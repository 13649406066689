import { Controller } from "@hotwired/stimulus"
import BulkInvestments from "../modules/bulk_investments"

export default class extends Controller {
  static targets = ["container", "assetClassSelect", "entitySelect", "custodianSelect", "form", "holdingsSummary", "issueFundamentals", "calculationsSummary"]

  connect() {
    console.log("QuickTrades controller connected")
    this.initializeAssetClassSelector()
    this.initializeEntityCustodianSelectors()
    // Only initialize BulkInvestments after asset class is selected and table is visible
  }

  disconnect() {
    console.log("QuickTrades controller disconnected")
    if (this.bulkInvestments) {
      this.bulkInvestments.destroy()
    }
  }

  initializeAssetClassSelector() {
    if (this.assetClassSelectTarget) {
      // Restore the previously selected asset class
      const savedAssetClassId = localStorage.getItem('selectedAssetClassId')
      if (savedAssetClassId) {
        this.assetClassSelectTarget.value = savedAssetClassId
        this.handleAssetClassChange({ target: this.assetClassSelectTarget })
      }

      this.assetClassSelectTarget.addEventListener('change', (e) => this.handleAssetClassChange(e))
    }
  }

  initializeEntityCustodianSelectors() {
    if (this.entitySelectTarget && this.custodianSelectTarget) {
      // Update hidden fields when selects change
      this.entitySelectTarget.addEventListener('change', () => {
        document.getElementById('selected_entity_id').value = this.entitySelectTarget.value
      })
      this.custodianSelectTarget.addEventListener('change', () => {
        document.getElementById('selected_custodian_id').value = this.custodianSelectTarget.value
      })

      // Set initial values
      document.getElementById('selected_entity_id').value = this.entitySelectTarget.value
      document.getElementById('selected_custodian_id').value = this.custodianSelectTarget.value
    }
  }

  handleAssetClassChange(e) {
    const assetClassId = e.target.value
    if (!assetClassId) {
      this.containerTarget.style.display = 'none'
      localStorage.removeItem('selectedAssetClassId')
      return
    }

    // Store the selected asset class ID
    document.getElementById('selected_asset_class_id').value = assetClassId
    
    // Save to localStorage
    localStorage.setItem('selectedAssetClassId', assetClassId)
    
    // Show the quick trade container
    this.containerTarget.style.display = 'block'

    // Get the dashboard type for this asset class
    const dashboards = JSON.parse(this.assetClassSelectTarget.dataset.dashboards || '{}')
    const dashboard = assetClassId.endsWith('equity_options') ? 'options' : dashboards[assetClassId]
    
    // Store the dashboard type in a hidden input for BulkInvestments
    const dashboardInput = document.getElementById('holdings-asset-class-dashboard') || (() => {
      const input = document.createElement('input')
      input.type = 'hidden'
      input.id = 'holdings-asset-class-dashboard'
      this.holdingsSummaryTarget.appendChild(input)
      return input
    })()
    dashboardInput.value = dashboard

    document.getElementById('calculations-summary').style.display = 'none'
    document.getElementById('issue-fundamentals').style.display = 'none'
    
    // Customize table based on dashboard type
    this.customizeTableForDashboard(dashboard)

    // Initialize BulkInvestments after table is customized and visible
    this.initializeBulkInvestments()

    // filter latest trades table
    this.filterLatestTradesTable(dashboard)
  }

  filterLatestTradesTable(dashboard) {
    const latestTradesTable = document.getElementById('latest-trades')
    latestTradesTable.querySelectorAll('tr').forEach(tr => {
      if (tr.classList.contains(dashboard)) {
        tr.style.display = 'table-row'
      } else {
        tr.style.display = 'none'
      }
    })
  }

  customizeTableForDashboard(dashboard) {
    // Reset any existing customizations
    this.resetTableCustomizations()

    switch(dashboard) {
      case 'cash':
        this.customizeCashTable()
        break
      case 'bonds':
        this.customizeBondsTable()
        break
      case 'equities':
        this.customizeEquitiesTable()
        break
      case 'options':
        this.customizeOptionsTable()
        break
      case 'fx_options':
        this.customizeFxOptionsTable()
        break
      case 'pivot_target':
        this.customizePivotTargetTable()
        break
      default:
        this.customizeDefaultTable()
    }

    // Clear any existing rows except the first one
    const tbody = document.getElementById('investments-tbody')
    while (tbody.children.length > 1) {
      tbody.removeChild(tbody.lastChild)
    }

    // Reset the first row
    const firstRow = tbody.firstElementChild
    if (firstRow) {
      // Clear all inputs except date fields
      firstRow.querySelectorAll('input:not([type="hidden"])').forEach(input => {
        input.value = ''
        input.readOnly = false
        input.disabled = false
      })

      firstRow.querySelectorAll('select').forEach(select => {
        select.readOnly = false
        select.disabled = false
      })

      firstRow.querySelectorAll('.badge').forEach(badge => {
        badge.remove()
      })

      // Set default dates
      const tradeDateField = firstRow.querySelector('input[name*="trade_date"]')
      const valueDateField = firstRow.querySelector('input[name*="value_date"]')
      if (tradeDateField) {
        tradeDateField.value = this.formatDateToISO(new Date())
      }
      if (valueDateField) {
        const tomorrow = new Date()
        tomorrow.setDate(tomorrow.getDate() + 1)
        valueDateField.value = this.formatDateToISO(tomorrow)
      }
      
      // Reset all selects
      firstRow.querySelectorAll('select').forEach(select => {
        select.selectedIndex = 0
      })
    }

    // Hide both summary cards initially
    this.holdingsSummaryTarget.style.display = 'none'
    this.calculationsSummaryTarget.style.display = 'none'
  }

  resetTableCustomizations() {
    // Reset any custom columns or styling for all elements on the page
    document.querySelectorAll('.bond-specific, .equity-specific, .option-specific, .fx-option-specific, .pivot-target-specific').forEach(el => {
      el.style.display = 'none'
    })
    document.querySelectorAll('.security-id-column').forEach(el => el.style.display = '')
    document.querySelectorAll('.security-name-column').forEach(el => el.style.display = '')
    document.querySelectorAll('.price-column').forEach(el => el.style.display = '')
    document.querySelectorAll('.quantity-column').forEach(el => el.style.display = '')
    document.querySelectorAll('.total-amount-column').forEach(el => el.style.display = '')
    document.querySelectorAll('.currency-column').forEach(el => el.style.display = '')
    document.querySelectorAll('.action-column').forEach(el => el.style.display = '')
    
  }

  customizeCashTable() {
    // Show all cash-specific elements on the page
    document.querySelectorAll('.cash-specific').forEach(el => el.style.display = '')
    document.querySelectorAll('.security-id-column').forEach(el => el.style.display = 'none')
    document.querySelectorAll('.security-name-column').forEach(el => el.style.display = 'none')
    document.querySelectorAll('.action-column').forEach(el => el.style.display = 'none')
    document.querySelectorAll('.quantity-column').forEach(el => el.style.display = 'none')
    document.querySelectorAll('.price-column').forEach(el => el.style.display = 'none')
  }

  customizeBondsTable() {
    // Show all bond-specific elements on the page
    document.querySelectorAll('.bond-specific').forEach(el => el.style.display = '')
    document.querySelectorAll('.quantity-unit').forEach(el => el.textContent = 'Nominal')
  }

  customizeEquitiesTable() {
    document.querySelectorAll('.equity-specific').forEach(el => el.style.display = '')
    document.querySelectorAll('.quantity-unit').forEach(el => el.textContent = 'Shares')
  }

  customizeOptionsTable() {
    document.querySelectorAll('.option-specific').forEach(el => el.style.display = '')
    document.querySelectorAll('.quantity-unit').forEach(el => el.textContent = 'Contracts')
  }

  customizeFxOptionsTable() {
    document.querySelectorAll('.fx-option-specific').forEach(el => el.style.display = '')
    document.querySelectorAll('.security-id-column').forEach(el => el.style.display = 'none')
    document.querySelectorAll('.security-name-column').forEach(el => el.style.display = 'none')
    document.querySelectorAll('.price-column').forEach(el => el.style.display = 'none')
    document.querySelectorAll('.quantity-column').forEach(el => el.style.display = 'none')
  }

  customizePivotTargetTable() {
    document.querySelectorAll('.pivot-target-specific').forEach(el => el.style.display = '')
    document.querySelectorAll('.security-id-column').forEach(el => el.style.display = 'none')
    document.querySelectorAll('.security-name-column').forEach(el => el.style.display = 'none')
    document.querySelectorAll('.price-column').forEach(el => el.style.display = 'none')
    document.querySelectorAll('.quantity-column').forEach(el => el.style.display = 'none')
    document.querySelectorAll('.total-amount-column').forEach(el => el.style.display = 'none')
    document.querySelectorAll('.currency-column').forEach(el => el.style.display = 'none')
    document.querySelectorAll('.action-column').forEach(el => el.style.display = 'none')
  }

  customizeDefaultTable() {
    document.querySelectorAll('.quantity-unit').forEach(el => el.textContent = 'Units')
  }

  formatDateToISO(date) {
    const d = new Date(date)
    return d.getFullYear() + '-' + 
           String(d.getMonth() + 1).padStart(2, '0') + '-' + 
           String(d.getDate()).padStart(2, '0')
  }

  initializeBulkInvestments() {
    const tbody = document.getElementById('investments-tbody')
    if (tbody && tbody.children.length > 0) {
      // Clean up previous instance if it exists
      if (this.bulkInvestments) {
        this.bulkInvestments.destroy()
      }
      // Wait for the next tick to ensure DOM is updated
      setTimeout(() => {
        this.bulkInvestments = new BulkInvestments()
      }, 0)
    }
  }
} 