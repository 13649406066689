export default class BulkInvestments {
  constructor() {
    this.tbody = document.getElementById('investments-tbody')
    if (!this.tbody) return

    this.addRowBtn = document.getElementById('add-row-btn')
    this.saveBtn = document.querySelector('.investment-form [type="submit"]')
    this.rowCounter = this.tbody.children.length
    this.activeRow = null
    
    this.initialize()

    // Setup form submit handler
    const form = document.querySelector('.investment-form')
    if (form) {
      form.addEventListener('submit', (e) => {
        e.preventDefault()

        // Clean up all formatted numbers before submission
        this.tbody.querySelectorAll('.quantity, .fees, .total-cost').forEach(field => {
          if (field.value) {
            field.value = this.parseNumber(field.value).toString()
          }
        })

        // Get the selected entity and custodian IDs
        const entityId = document.getElementById('selected_entity_id')?.value
        const custodianId = document.getElementById('selected_custodian_id')?.value

        // Add entity_id and custodian_id to each investment row
        this.tbody.querySelectorAll('.investment-row').forEach(row => {
          // Create hidden fields for entity and custodian if they don't exist
          if (!row.querySelector('input[name*="[entity_id]"]')) {
            const entityInput = document.createElement('input')
            entityInput.type = 'hidden'
            entityInput.name = `investments[][entity_id]`
            entityInput.value = entityId
            row.appendChild(entityInput)
          }
          if (!row.querySelector('input[name*="[custodian_id]"]')) {
            const custodianInput = document.createElement('input')
            custodianInput.type = 'hidden'
            custodianInput.name = `investments[][custodian_id]`
            custodianInput.value = custodianId
            row.appendChild(custodianInput)
          }
        })

        // Submit form via AJAX
        const formData = new FormData(form)
        fetch(form.action, {
          method: 'POST',
          body: formData,
          headers: {
            'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
            'Accept': 'application/json'
          }
        })
        .then(response => {
          if (!response.ok) {
            return response.json().then(data => {
              throw new Error(data.error || 'Network response was not ok')
            })
          }
          return response.json()
        })
        .then(data => {
          // Reset the table after download
          setTimeout(() => {
            window.location.reload()
            // Keep only the first row and reset its values
            const tbody = this.tbody
            while (tbody.children.length > 1) {
              tbody.removeChild(tbody.lastChild)
            }
            
            // Reset the first row
            const firstRow = tbody.firstElementChild
            if (firstRow) {
              // Clear all inputs except date fields
              firstRow.querySelectorAll('input:not([type="hidden"]):not([type="date"])').forEach(input => {
                input.value = ''
                input.readOnly = false
                input.disabled = false
              })

              firstRow.querySelectorAll('.badge').forEach(badge => {
                badge.remove()
              })

              // Reset all selects and make them enabled
              firstRow.querySelectorAll('select').forEach(select => {
                select.selectedIndex = 0
                select.readOnly = false
                select.disabled = false
              })

              // Set default dates
              const tradeDateField = firstRow.querySelector('input[name*="trade_date"]')
              const valueDateField = firstRow.querySelector('input[name*="value_date"]')
              if (tradeDateField) {
                tradeDateField.value = this.formatDateToISO(new Date())
              }
              if (valueDateField) {
                const tomorrow = new Date()
                tomorrow.setDate(tomorrow.getDate() + 1)
                valueDateField.value = this.formatDateToISO(tomorrow)
              }
            }

            // Hide summary cards
            document.getElementById('holdings-summary').style.display = 'none'
            document.getElementById('issue-fundamentals').style.display = 'none'
            document.getElementById('calculations-summary').style.display = 'none'
          }, 1000) // Wait for download to start
          
          if (data.success) {
            // Show success modal
            const successModal = new bootstrap.Modal(document.getElementById('successModal'))
            
            // Update download summary button URL if provided
            const downloadBtn = document.getElementById('download-summary-btn')
            if (downloadBtn) {
              if (data.excel_url) {
                console.log('Setting download URL:', data.excel_url)  // Debug log
                downloadBtn.href = data.excel_url
                // Add click handler to trigger download and reset table
                downloadBtn.addEventListener('click', (e) => {
                  e.preventDefault()
                  window.location.href = data.excel_url
                })
              } else if (data.download_url) {
                console.log('Setting download URL:', data.download_url)  // Debug log
                downloadBtn.href = data.download_url
                // Add click handler to trigger download
                downloadBtn.addEventListener('click', (e) => {
                  e.preventDefault()
                  window.location.href = data.download_url
                })
              } else {
                console.log('No download URL found in response:', data)  // Debug log
              }
            } else {
              console.log('Download button not found')  // Debug log
            }

            successModal.show()
          } else {
            throw new Error(data.error || 'Unknown error occurred')
          }
        })
        .catch(error => {
          console.error('Error:', error)
          // Create or get error alert div
          let errorAlert = document.querySelector('.investment-form-error')
          if (!errorAlert) {
            errorAlert = document.createElement('div')
            errorAlert.className = 'alert alert-danger investment-form-error'
            form.insertBefore(errorAlert, form.firstChild)
          }
          errorAlert.textContent = error.message || 'There was an error processing your request. Please try again.'
          errorAlert.style.display = 'block'
          
          // Scroll to error message
          errorAlert.scrollIntoView({ behavior: 'smooth', block: 'center' })
        })
      })
    }
  }

  initialize() {
    // Setup initial rows
    Array.from(this.tbody.children).forEach(row => this.setupRowEventListeners(row))
    
    // Bind addNewRow to preserve context
    this.boundAddNewRow = () => this.addNewRow()
    
    // Add row button handler
    if (this.addRowBtn) {
      this.addRowBtn.addEventListener('click', this.boundAddNewRow)
    }

    // Setup date fields to enforce ISO format
    this.setupDateFields()

    // Setup form submit handler
    const form = document.querySelector('.investment-form')
    if (form) {
      form.addEventListener('submit', (e) => {
        // Clean up all formatted numbers before submission
        this.tbody.querySelectorAll('.quantity, .fees, .total-cost').forEach(field => {
          if (field.value) {
            field.value = this.parseNumber(field.value).toString()
          }
        })
      })
    }
    
    this.updateRemoveButtons()
  }

  formatNumber(number, decimals = 2) {
    const parts = number.toString().split('.')
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    
    if (decimals > 0) {
      // Handle decimal part if it exists
      if (parts.length > 1) {
        // Pad or truncate existing decimal part
        parts[1] = parts[1].padEnd(decimals, '0').slice(0, decimals)
      } else {
        // Add decimal part if it doesn't exist
        parts[1] = '0'.repeat(decimals)
      }
      return parts.join('.')
    }
    return parts[0]  // Return just the integer part if decimals is 0
  }

  parseNumber(str) {
    return parseFloat(str.replace(/,/g, '')) || 0
  }

  formatDateToISO(date) {
    const d = new Date(date)
    return d.getFullYear() + '-' + 
           String(d.getMonth() + 1).padStart(2, '0') + '-' + 
           String(d.getDate()).padStart(2, '0')
  }

  setupDateFields() {
    const dateFields = document.querySelectorAll('input[type="date"]')
    dateFields.forEach(field => {
      // Set initial value in ISO format
      if (field.value) {
        field.value = this.formatDateToISO(field.value)
      }

      // Handle any changes to ensure ISO format
      field.addEventListener('change', (e) => {
        if (e.target.value) {
          e.target.value = this.formatDateToISO(e.target.value)
        }
      })
    })
  }

  updateCalculations(row) {
    if (!row) return

    const quantityField = row.querySelector('.quantity')
    const priceField = row.querySelector('.price')
    const couponRateField = row.querySelector('.coupon-rate')
    const totalCostField = row.querySelector('.total-cost')
    const currencySymbol = row.querySelector('.currency-symbol').textContent
    const assetClassSelect = document.querySelector('[data-quick-trades-target="assetClassSelect"]')
    const selectedAssetClassId = assetClassSelect.value

    const quantity = this.parseNumber(quantityField.value)
    const price = this.parseNumber(priceField.value)

    // Get the asset class dashboard to determine calculation method
    const assetClassDashboard = document.getElementById('holdings-asset-class-dashboard')?.value
    
    let estimatedCost = 0
    let totalAccruedInterest = 0
    let expectedCoupon = 0

    if (assetClassDashboard === 'bonds') {
      // Bond calculations
      const denomination = this.parseNumber(document.getElementById('holdings-denomination')?.value || '1')
      estimatedCost = (quantity * price) / denomination
      
      // Calculate accrued interest for bonds
      const holdingsQuantity = this.parseNumber(document.getElementById('holdings-quantity')?.textContent || '1')
      const holdingsAccruedInterestPercentage = this.parseNumber(document.getElementById('holdings-accrued-interest-percentage')?.textContent || '0')
      const accruedInterestPerUnit = holdingsAccruedInterestPercentage / 100
      totalAccruedInterest = quantity * accruedInterestPerUnit

      // Calculate expected coupon
      const couponRate = this.parseNumber(couponRateField.value || '0') / 100
      expectedCoupon = (couponRate * quantity)
    
    } else if (selectedAssetClassId.endsWith('equity_options')) {
      // Options calculations - multiply by 100 for contract size
      estimatedCost = quantity * price * 100
    } else {
      // Standard calculations for other asset types (equities, etc.)
      estimatedCost = quantity * price
    }
    
    // Calculate Total Amount
    const totalCost = estimatedCost + totalAccruedInterest
    
    // Update Total Amount field
    totalCostField.value = this.formatNumber(totalCost, 0)

    // Update calculations summary
    const calcSummary = document.getElementById('calculations-summary')
    if (calcSummary) {
      calcSummary.style.display = 'block'
      
      // Update the cost breakdown
      document.getElementById('calc-principal').textContent = this.formatNumber(estimatedCost, 0)
      document.getElementById('calc-principal-currency').textContent = currencySymbol
      
      // Only show accrued interest for bonds
      const accruedInterestRow = calcSummary.querySelector('.accrued-interest-row')
      if (accruedInterestRow) {
        if (assetClassDashboard === 'bonds') {
          accruedInterestRow.style.display = ''
          document.getElementById('calc-accrued-interest').textContent = this.formatNumber(totalAccruedInterest, 0)
          document.getElementById('calc-accrued-interest-currency').textContent = currencySymbol
        } else {
          accruedInterestRow.style.display = 'none'
        }
      }
      
      document.getElementById('calc-total').textContent = this.formatNumber(totalCost, 0)
      document.getElementById('calc-total-currency').textContent = currencySymbol

      // Update expected coupon for bonds
      const expectedCouponSection = calcSummary.querySelector('.bond-specific')
      if (expectedCouponSection) {
        if (assetClassDashboard === 'bonds') {
          expectedCouponSection.style.display = 'block'
          document.getElementById('calc-expected-coupon').textContent = this.formatNumber(expectedCoupon, 0)
          document.getElementById('calc-expected-coupon-currency').textContent = currencySymbol
        } else {
          expectedCouponSection.style.display = 'none'
        }
      }
    }
  }

  setupRowEventListeners(row) {
    if (!row) return

    const quantityField = row.querySelector('.quantity')
    const priceField = row.querySelector('.price')
    const feesField = row.querySelector('.fees')
    const lookupBtn = row.querySelector('.lookup-btn')
    const removeBtn = row.querySelector('.remove-row')
    const symbolInput = row.querySelector('.symbol-input')
    const nameInput = row.querySelector('.name-input')

    // Add click handler for the entire row to update summaries
    row.addEventListener('click', (e) => {
      // Remove active class from all rows
      this.tbody.querySelectorAll('.investment-row').forEach(r => r.classList.remove('active'))
      // Add active class to clicked row
      row.classList.add('active')
      // Set active row
      this.activeRow = row

      // Only update if there's a symbol value and the asset exists in DB
      if (symbolInput.value) {
        const statusPill = row.querySelector('.asset-status-pill')
        const entityId = document.getElementById('selected_entity_id')?.value
        const custodianId = document.getElementById('selected_custodian_id')?.value
        if (entityId && custodianId) {
          document.getElementById('calculations-summary').style.display = 'none'
          this.updateHoldingsSummary(symbolInput.value, entityId, custodianId)
          this.updateCalculations(row)
        }
      }
    })

    // Add input and blur event listeners for ISIN field
    symbolInput?.addEventListener('input', () => {
      if (symbolInput.value) {
        const entityId = document.getElementById('selected_entity_id')?.value
        const custodianId = document.getElementById('selected_custodian_id')?.value
        if (entityId && custodianId) {
          this.updateHoldingsSummary(symbolInput.value, entityId, custodianId)
        }
      }
    })

    symbolInput?.addEventListener('blur', () => {
      if (symbolInput.value) {
        const entityId = document.getElementById('selected_entity_id')?.value
        const custodianId = document.getElementById('selected_custodian_id')?.value
        if (entityId && custodianId) {
          this.updateHoldingsSummary(symbolInput.value, entityId, custodianId)
        }
      }
    })

    removeBtn?.addEventListener('click', () => {
      if (this.tbody.children.length > 1) {
        row.remove()
        this.updateRemoveButtons()
      }
    })

    // Add input event listeners for formatting numbers
    const fieldsToFormat = [quantityField, feesField].filter(field => field !== null)
    fieldsToFormat.forEach(field => {
      field?.addEventListener('input', (e) => {
        const value = this.parseNumber(e.target.value)
        if (!isNaN(value)) {
          // Keep the cursor position
          const cursorPos = e.target.selectionStart
          const oldLength = e.target.value.length
          // Use 0 decimals for quantity and fees
          const decimals = 0
          e.target.value = this.formatNumber(value, decimals)
          const newLength = e.target.value.length
          // Adjust cursor position if length changed
          const newPos = cursorPos + (newLength - oldLength)
          e.target.setSelectionRange(newPos, newPos)
        }
        this.updateCalculations(row)
      })

      // Format initial values if they exist
      if (field?.value) {
        const decimals = 0
        field.value = this.formatNumber(this.parseNumber(field.value), decimals)
      }
    })

    // Simple input handler for price field without formatting
    if (priceField) {
      priceField.addEventListener('input', () => {
        this.updateCalculations(row)
      })
    }
  }

  updateRemoveButtons() {
    const removeButtons = this.tbody.querySelectorAll('.remove-row')
    const isLastRow = this.tbody.children.length === 1
    removeButtons.forEach(btn => {
      btn.disabled = isLastRow
    })
  }

  addNewRow() {
    const lastRow = this.tbody.children[this.tbody.children.length - 1]
    const template = this.tbody.children[0].cloneNode(true)
    template.dataset.row = this.rowCounter++

    // Remove active class from all rows
    this.tbody.querySelectorAll('.investment-row').forEach(r => r.classList.remove('active'))
    
    // Clear inputs except entity, custodian and dates
    template.querySelectorAll('input:not([type="hidden"])').forEach(input => {
      input.value = ''
      input.readOnly = false
      input.disabled = false
    })

    template.querySelectorAll('.badge').forEach(badge => {
      badge.remove()
    })

    template.querySelectorAll('.invalid-feedback').forEach(pill => {
      pill.remove()
    })

    // Set default dates
    const tradeDateField = template.querySelector('input[name*="trade_date"]')
    const valueDateField = template.querySelector('input[name*="value_date"]')
    if (tradeDateField) {
      tradeDateField.value = this.formatDateToISO(new Date())
    }
    if (valueDateField) {
      const tomorrow = new Date()
      tomorrow.setDate(tomorrow.getDate() + 1)
      valueDateField.value = this.formatDateToISO(tomorrow)
    }

    // Get current entity and custodian IDs
    const entityId = document.getElementById('selected_entity_id')?.value
    const custodianId = document.getElementById('selected_custodian_id')?.value

    // Update or create hidden fields for entity and custodian
    let entityInput = template.querySelector('input[name*="[entity_id]"]')
    let custodianInput = template.querySelector('input[name*="[custodian_id]"]')

    if (!entityInput) {
      entityInput = document.createElement('input')
      entityInput.type = 'hidden'
      entityInput.name = `investments[][entity_id]`
      template.appendChild(entityInput)
    }
    if (!custodianInput) {
      custodianInput = document.createElement('input')
      custodianInput.type = 'hidden'
      custodianInput.name = `investments[][custodian_id]`
      template.appendChild(custodianInput)
    }

    entityInput.value = entityId
    custodianInput.value = custodianId

    // Reset other selects and make sure they're enabled
    template.querySelectorAll('select').forEach(select => {
      if (!select.name.includes('entity_id') && !select.name.includes('custodian_id')) {
        select.selectedIndex = 0
        select.readOnly = false
        select.disabled = false
      }
    })

    // Enable remove button
    const removeBtn = template.querySelector('.remove-row')
    if (removeBtn) {
      removeBtn.disabled = false
    }

    // Clear security description
    const securityDescription = template.querySelector('.security-description')
    if (securityDescription) {
      securityDescription.textContent = ''
    }

    // Make sure bond-specific fields are writable
    template.querySelectorAll('.bond-specific input, .bond-specific select').forEach(field => {
      field.readOnly = false
      field.disabled = false
    })

    this.tbody.appendChild(template)
    this.setupRowEventListeners(template)
    this.updateRemoveButtons()
  }

  updateHoldingsSummary(isin, entityId, custodianId) {
    if (!isin || !entityId || !custodianId) {
      document.getElementById('holdings-summary').style.display = 'none'
      document.getElementById('issue-fundamentals').style.display = 'none'
      // Clear error state when field is empty
      const symbolInput = document.querySelector('.symbol-input')
      if (symbolInput) {
        symbolInput.classList.remove('is-invalid')
        // Remove all existing feedback divs
        const parentCell = symbolInput.parentElement.parentElement
        parentCell.querySelectorAll('.invalid-feedback').forEach(div => div.remove())
      }
      return
    }

    // Get the current row - either the active row from click or the row with focus
    const currentRow = this.activeRow || document.activeElement.closest('tr')
    if (!currentRow) return

    // Get the selected asset class
    const assetClassSelect = document.querySelector('[data-quick-trades-target="assetClassSelect"]')
    if (!assetClassSelect) {
      console.warn('Asset class select element not found')
      return
    }
    const selectedAssetClassId = assetClassSelect.value
    const selectedAssetClassName = assetClassSelect.options[assetClassSelect.selectedIndex]?.text

    // Find all the fields in the row that need to be updated
    const lastPriceField = currentRow.querySelector('.last-price')
    const currencySymbolSpans = currentRow.querySelectorAll('.currency-symbol')
    const assetClassField = currentRow.querySelector('.asset-class')
    const assetClassIdField = currentRow.querySelector('.asset-class-id')
    const symbolInput = currentRow.querySelector('.symbol-input')
    const nameInput = currentRow.querySelector('.name-input')
    const quantityField = currentRow.querySelector('.quantity')
    
    // Clean up any existing error states and status pills
    symbolInput.classList.remove('is-invalid')
    const parentCell = symbolInput.parentElement.parentElement
    parentCell.querySelectorAll('.invalid-feedback').forEach(div => div.remove())

    // Reset the fields before fetching new data
    if (lastPriceField) lastPriceField.value = ''
    if (assetClassField) assetClassField.value = ''
    if (assetClassIdField) assetClassIdField.value = ''
    
    currencySymbolSpans.forEach(span => span.textContent = 'USD')

    fetch(`/assets/holdings_summary?isin=${isin}&entity_id=${entityId}&custodian_id=${custodianId}`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Asset not found')
        }
        return response.json()
      })
      .then(data => {
        // Check if the asset's class matches the selected one, but allow equity_options for options
        if (data.asset_class_id.toString() !== selectedAssetClassId.toString()) {
          throw new Error(`${data.asset_class_name}, not ${selectedAssetClassName}`)
        }

        if (nameInput) nameInput.value = ''
        // Clean up any existing error states and status pills
        symbolInput.classList.remove('is-invalid')
        parentCell.querySelectorAll('.invalid-feedback, .asset-status-pill').forEach(div => div.remove())

        // Add "in db" status pill
        const statusPill = document.createElement('span')
        statusPill.classList.add('badge', 'bg-success', 'ms-2', 'asset-status-pill', 'in-db')
        statusPill.textContent = 'existing'
        symbolInput.parentElement.appendChild(statusPill)

        // Show both cards
        document.getElementById('holdings-summary').style.display = 'block'
        document.getElementById('issue-fundamentals').style.display = 'block'

        // Update the name field in the row
        if (nameInput && data.name) {
          nameInput.value = data.name
        }

        // Update the holdings summary card
        document.getElementById('holdings-asset-class-dashboard').textContent = selectedAssetClassId.endsWith('equity_options') ? 'Equity Options' : data.asset_class_name
        const assetNameLink = document.getElementById('holdings-name')
        assetNameLink.textContent = data.name
        assetNameLink.dataset.assetId = data.asset_id
        document.getElementById('holdings-isin').textContent = data.isin
        
        // Store the denomination for calculations
        const denominationField = document.getElementById('holdings-denomination') || (() => {
          const field = document.createElement('input')
          field.type = 'hidden'
          field.id = 'holdings-denomination'
          document.getElementById('holdings-summary').appendChild(field)
          return field
        })()
        denominationField.value = data.denomination || 1
        
        const displayQuantity = data.quantity
        document.getElementById('holdings-quantity').textContent = displayQuantity.toLocaleString()
        document.getElementById('holdings-quantity-label').textContent = `@ ${data.entity_name} / ${data.custodian_name}`
        
        // Update price field in the row
        const priceField = currentRow.querySelector('.price')
        if (priceField && data.latest_price) {
          priceField.value = data.latest_price.toFixed(4)
        }

        // Update currency symbols
        currencySymbolSpans.forEach(span => {
          span.textContent = data.currency_symbol || 'USD'
        })

        // Handle bond-specific fields in the table row
        const couponRateField = currentRow.querySelector('.coupon-rate')
        const maturityField = currentRow.querySelector('.maturity-date')
        const frequencyField = currentRow.querySelector('.coupon-frequency')
        const currencyField = currentRow.querySelector('.currency-select')

        // Handle currency field - update and make readonly if populated from API
        if (currencyField && data.currency) {
          currencyField.value = data.currency.id
          currencyField.readOnly = true
          currencyField.disabled = true
        }

        if (data.asset_class_dashboard === 'bonds') {
          // Show bond-specific fields
          currentRow.querySelectorAll('.bond-specific').forEach(el => {
            el.style.display = ''
          })

          // Handle each field independently
          // Handle coupon rate - only make readonly if populated from API
          if (couponRateField) {
            if (data.coupon_rate !== null && data.coupon_rate !== undefined) {
              const couponValue = parseFloat(data.coupon_rate)
              couponRateField.value = couponValue ? couponValue.toFixed(3) : ''
              couponRateField.readOnly = true
              couponRateField.disabled = true
            } else {
              // Keep existing value if any, and make sure it's editable
              couponRateField.readOnly = false
              couponRateField.disabled = false
            }
          }

          // Handle maturity date - only make readonly if populated from API
          if (maturityField) {
            if (data.maturity) {
              maturityField.value = this.formatDateToISO(data.maturity)
              maturityField.readOnly = true
            } else {
              // Keep existing value if any, and make sure it's editable
              maturityField.readOnly = false
              maturityField.disabled = false
            }
          }

          // Handle frequency - only disable if populated from API
          if (frequencyField) {
            if (data.frequency) {
              // Normalize frequency value to match select options
              const frequencyMap = {
                1: 'Annual',
                2: 'Semi-Annual',
                4: 'Quarterly',
                12: 'Monthly'
              }
              // Convert to string to handle both string and number inputs
              const frequencyKey = data.frequency.toString()
              const normalizedFrequency = frequencyMap[frequencyKey] || data.frequency
              if (normalizedFrequency) {
                frequencyField.value = normalizedFrequency
                frequencyField.readOnly = true
                frequencyField.disabled = true
              } else {
                // Keep existing value if any, and make sure it's editable
                frequencyField.readOnly = false
                frequencyField.disabled = false
              }
            } else {
              // Keep existing value if any, and make sure it's editable
              frequencyField.readOnly = false
              frequencyField.disabled = false
            }
          }
        } else {
          // Hide bond-specific fields for non-bond asset classes
          currentRow.querySelectorAll('.bond-specific').forEach(el => {
            el.style.display = 'none'
          })
        }

        // Handle option-specific fields in the table row
        if (selectedAssetClassId.endsWith('equity_options')) {
          // Show option-specific fields
          currentRow.querySelectorAll('.option-specific').forEach(el => {
            el.style.display = ''
          })

          // Handle strike price
          const strikePriceField = currentRow.querySelector('.strike-price')
          if (strikePriceField && data.strike_price) {
            strikePriceField.value = data.strike_price
            strikePriceField.readOnly = true
            strikePriceField.disabled = true
          }

          // Handle expiry date
          const expiryDateField = currentRow.querySelector('input[name*="expiration_date"]')
          if (expiryDateField && data.expiration_date) {
            expiryDateField.value = this.formatDateToISO(data.expiration_date)
            expiryDateField.readOnly = true
          }

          // Handle option type
          const optionTypeField = currentRow.querySelector('select[name*="option_type"]')
          if (optionTypeField && data.option_type) {
            optionTypeField.value = data.option_type
            optionTypeField.readOnly = true
          }
        } else {
          // Hide option-specific fields for non-option asset classes
          currentRow.querySelectorAll('.option-specific').forEach(el => {
            el.style.display = 'none'
          })
        }

        // Update the summary cards
        if (data.latest_price) {
          document.getElementById('holdings-latest-price').textContent = data.latest_price.toFixed(4)
          document.getElementById('holdings-currency-symbol').textContent = data.currency_symbol
          
          if (data.liquidation_value) {
            document.getElementById('holdings-value').textContent = this.formatNumber(data.liquidation_value, 0)
            document.getElementById('holdings-value-currency').textContent = data.currency_symbol
          } else {
            document.getElementById('holdings-value').textContent = '-'
            document.getElementById('holdings-value-currency').textContent = ''
          }
        } else {
          document.getElementById('holdings-latest-price').textContent = '-'
          document.getElementById('holdings-currency-symbol').textContent = ''
          document.getElementById('holdings-value').textContent = '-'
          document.getElementById('holdings-value-currency').textContent = ''
        }

         // Update bond-specific fields in the summary cards
         if (data.next_coupon_date) {
          document.getElementById('holdings-next-coupon-date').textContent = data.next_coupon_date
        }
        if (data.current_coupon_payment) {
          document.getElementById('holdings-expected-coupon').textContent = this.formatNumber(data.current_coupon_payment, 0)
          document.getElementById('holdings-expected-coupon-currency').textContent = data.currency_symbol
        }
        if (data.accrued_interest) {
          document.getElementById('holdings-accrued-interest').textContent = this.formatNumber(data.accrued_interest, 0)
          document.getElementById('holdings-accrued-interest-currency').textContent = data.currency_symbol
        }
        if (data.accrued_interest_percentage) {
          document.getElementById('holdings-accrued-interest-percentage').textContent = data.accrued_interest_percentage * 100
        }

        // Update calculations if quantity exists
        if (quantityField?.value) {
          this.updateCalculations(currentRow)
        }

      })
      .catch(error => {
        console.error('Error fetching holdings:', error)
        document.getElementById('holdings-summary').style.display = 'none'
        document.getElementById('issue-fundamentals').style.display = 'none'
        
        // Only show error if there's actually a value in the input
        if (symbolInput.value.trim()) {
          // Clean up any existing error states and status pills
          parentCell.querySelectorAll('.invalid-feedback, .asset-status-pill').forEach(div => div.remove())
          
          // Add error state
          symbolInput.classList.add('is-invalid')
          const feedbackDiv = document.createElement('div')
          feedbackDiv.classList.add('invalid-feedback', 'show')
          
          if (error.message === 'Asset not found') {

            currentRow.querySelectorAll('input, select').forEach(el => {
              el.readOnly = false
              el.disabled = false
            })

            // Add "to be added" status pill
            const statusPill = document.createElement('span')
            statusPill.classList.add('badge', 'bg-warning', 'ms-2', 'asset-status-pill', 'to-be-added')
            statusPill.textContent = 'new'
            symbolInput.parentElement.appendChild(statusPill)

            feedbackDiv.innerHTML = 'Issue not found, will be created.'
            if (true) {//window.hasBloomberg) {
              feedbackDiv.innerHTML += ` <a href="#" class="fetch-bloomberg-data">Fetch from Bloomberg</a>`
              
              // Add click handler for the Bloomberg fetch link
              const bloombergLink = feedbackDiv.querySelector('.fetch-bloomberg-data')
              bloombergLink.addEventListener('click', (e) => {
                e.preventDefault()
                
                // Show loading state with animated ellipsis
                let dots = 0
                const animateEllipsis = () => {
                  if (bloombergLink.textContent.startsWith('Fetching')) {
                    bloombergLink.textContent = 'Fetching' + '.'.repeat(dots)
                    dots = (dots + 1) % 4
                    setTimeout(animateEllipsis, 500)
                  }
                }
                
                bloombergLink.textContent = 'Fetching'
                bloombergLink.style.pointerEvents = 'none'
                animateEllipsis()
                
                // Call the Bloomberg service
                fetch(`/assets/fetch_bloomberg_data?isin=${symbolInput.value}&asset_class_id=${selectedAssetClassId}`, {
                  method: 'POST',
                  headers: {
                    'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
                  }
                })
                .then(response => {
                  if (!response.ok) throw new Error('Failed to fetch Bloomberg data')
                  return response.json()
                })
                .then(data => {
                  // Update the issue fundamentals card with Bloomberg data
                  document.getElementById('issue-fundamentals').style.display = 'block'
                  
                  if (data.latest_price) {
                    document.getElementById('holdings-latest-price').textContent = data.latest_price.toFixed(4)
                    document.getElementById('holdings-currency-symbol').textContent = data.currency_symbol || 'USD'
                    
                    // Update the price field in the form if it's empty
                    const priceField = currentRow.querySelector('.price')
                    if (priceField && !priceField.value) {
                      priceField.value = data.latest_price.toFixed(4)
                    }
                  }
                  
                   // Update bond-specific fields if available
                   if (data.maturity) {
                    document.getElementById('holdings-maturity').textContent = this.formatDateToISO(data.maturity)
                  }
                  if (data.frequency) {
                    document.getElementById('holdings-frequency').textContent = data.frequency
                  }
                  if (data.coupon_rate) {
                    document.getElementById('holdings-coupon').textContent = data.coupon_rate
                  }
                  if (data.next_coupon_date) {
                    document.getElementById('holdings-next-coupon-date').textContent = data.next_coupon_date
                  }

                  // Update option-specific fields if available
                  if (selectedAssetClassId.endsWith('equity_options')) {
                    if (data.strike_price) {
                      const strikePriceField = currentRow.querySelector('.strike-price')
                      if (strikePriceField) {
                        strikePriceField.value = data.strike_price
                        strikePriceField.readOnly = true
                        strikePriceField.disabled = true
                      }
                    }
                    if (data.expiration_date) {
                      const expiryDateField = currentRow.querySelector('input[name*="expiration_date"]')
                      if (expiryDateField) {
                        expiryDateField.value = this.formatDateToISO(data.expiration_date)
                        expiryDateField.readOnly = true
                        expiryDateField.disabled = true
                      }
                    }
                    if (data.option_type) {
                      const optionTypeField = currentRow.querySelector('select[name*="option_type"]')
                      if (optionTypeField) {
                        optionTypeField.value = data.option_type
                        optionTypeField.readOnly = true
                        optionTypeField.disabled = true
                      }
                    }
                  }
                  
                  // Update the feedback message
                  feedbackDiv.innerHTML = 'Asset created successfully from Bloomberg data.'
                  feedbackDiv.classList.remove('invalid-feedback')
                  feedbackDiv.classList.add('valid-feedback')
                  symbolInput.classList.remove('is-invalid')
                  symbolInput.classList.add('is-valid')

                  // Update currency symbols in the row
                  currencySymbolSpans.forEach(span => {
                    span.textContent = data.currency_symbol || 'USD'
                  })

                  // Trigger calculation update
                  this.updateCalculations(currentRow)
                })
                .catch(error => {
                  console.error('Bloomberg fetch error:', error)
                  feedbackDiv.textContent = 'Failed to fetch Bloomberg data. Please try again.'
                  bloombergLink.textContent = 'Fetch from Bloomberg'
                  bloombergLink.style.pointerEvents = 'auto'
                })
              })
            }
          } else {
            feedbackDiv.textContent = error.message
          }
          
          parentCell.appendChild(feedbackDiv)

        }
      })
  }

  destroy() {
    // Remove event listeners
    if (this.addRowBtn) {
      this.addRowBtn.removeEventListener('click', this.boundAddNewRow)
    }
  }
} 