import consumer from "./consumer";

window.subscribeToProgressChannel = function (currentUserId) {
    const identifier = JSON.stringify({channel: "ProgressChannel", user_id: currentUserId});
    if (!consumer.subscriptions.findAll(identifier).length) {
        consumer.subscriptions.create({channel: "ProgressChannel", user_id: currentUserId}, {

                connected() {
                    // Called when the subscription is ready for use on the server
                    console.log("connected to ProgressChannel" + currentUserId)
                    var feedbackElement = $("#process-feedback");
                    feedbackElement.html("");
                },

                disconnected() {
                    // Called when the subscription has been terminated by the server
                },

                received(data) {
                    // Called when there's incoming data on the websocket for this channel
                    if (data.progress) {
                        const uniqueId = new Date().getTime();
                        console.log(`${uniqueId}: `, data);
                        const feedbackElement = $("#process-feedback");
                        feedbackElement.removeClass("d-none");

                        feedbackElement.append('<br>' + data.progress);
                        feedbackElement.scrollTop(feedbackElement[0].scrollHeight);
                    }
                    if (data.done) {
                        const feedbackElement = $("#process-feedback");
                        feedbackElement.html("");
                        //feedbackElement.addClass("d-none");
                    }
                }
            }
        );
    }
}