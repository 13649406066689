import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["switch"]

  connect() {
    console.log("AutoDiscount controller connected")
    this.switchTarget.addEventListener('change', this.toggleAutoDiscount.bind(this))
  }

  disconnect() {
    console.log("AutoDiscount controller disconnected")
  }

  toggleAutoDiscount(event) {
    console.log("Toggle auto discount", event.target.checked)
    const assetId = window.location.pathname.split('/').pop()
    console.log("Asset ID:", assetId)
    
    if (event.target.checked) {
      this.calculateDiscount(assetId)
    }
  }

  async calculateDiscount(assetId) {
    try {
      const response = await fetch(`/assets/${assetId}/calculate_auto_discount`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
          'Accept': 'application/json'
        },
        credentials: 'same-origin'
      })
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }
      
      const data = await response.json()
      console.log("Received discount:", data)
      
      // Update the best_in_place element
      const discountElement = document.querySelector('.best_in_place[data-attribute="discount"]')
      if (discountElement) {
        discountElement.textContent = `${data.discount}%`
      }
    } catch (error) {
      console.error('Error calculating auto discount:', error)
      this.switchTarget.checked = false
    }
  }
} 